exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-connected-car-tsx": () => import("./../../../src/pages/connected-car.tsx" /* webpackChunkName: "component---src-pages-connected-car-tsx" */),
  "component---src-pages-contacts-en-tsx": () => import("./../../../src/pages/contacts.en.tsx" /* webpackChunkName: "component---src-pages-contacts-en-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-documents-en-tsx": () => import("./../../../src/pages/documents.en.tsx" /* webpackChunkName: "component---src-pages-documents-en-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-hmi-software-tsx": () => import("./../../../src/pages/hmi-software.tsx" /* webpackChunkName: "component---src-pages-hmi-software-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[code]-tsx": () => import("./../../../src/pages/news/[code].tsx" /* webpackChunkName: "component---src-pages-news-[code]-tsx" */),
  "component---src-pages-playauto-tsx": () => import("./../../../src/pages/playauto.tsx" /* webpackChunkName: "component---src-pages-playauto-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-vacancies-[id]-tsx": () => import("./../../../src/pages/vacancies/[id].tsx" /* webpackChunkName: "component---src-pages-vacancies-[id]-tsx" */),
  "component---src-pages-vacancies-special-[id]-tsx": () => import("./../../../src/pages/vacancies/special/[id].tsx" /* webpackChunkName: "component---src-pages-vacancies-special-[id]-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */)
}

