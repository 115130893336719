import React, { createContext, ReactElement, useState, useContext } from 'react';

interface IAppContext {
    isContactFormVisible: boolean,
    setIsContactFormVisible: React.Dispatch<React.SetStateAction<boolean>> | (() => void),
    isRespondFormVisible: boolean,
    setIsRespondFormVisible: React.Dispatch<React.SetStateAction<boolean>> | (() => void),
    isNavVisible: boolean,
    setIsNavVisible: React.Dispatch<React.SetStateAction<boolean>> | (() => void),
    vacancyTitle: string,
    setVacancyTitle: React.Dispatch<React.SetStateAction<string>> | (() => void),
    huntflowId: string | null,
    huntflowId2: string | null,
    setHuntflowId: React.Dispatch<React.SetStateAction<string>> | (() => void),
    setHuntflowId2: React.Dispatch<React.SetStateAction<string>> | (() => void),
    mainPageActivePageId: string | null,
    setMainPageActivePageId: React.Dispatch<React.SetStateAction<string | null>> | (() => void),
    isVisibleFeedbackForms: boolean,
    isResumeFormVisible: boolean,
    setIsResumeFormVisible: React.Dispatch<React.SetStateAction<boolean>> | (() => void)
}

const appContext = createContext<IAppContext>({
    isContactFormVisible   : false,
    isRespondFormVisible   : false,
    isNavVisible           : false,
    setIsRespondFormVisible: () => {},
    setIsContactFormVisible: () => {},
    setIsNavVisible        : () => {},
    vacancyTitle           : '',
    setVacancyTitle        : () => {},
    huntflowId             : null,
    setHuntflowId          : () => {},
    huntflowId2            : null,
    setHuntflowId2         : () => {},
    mainPageActivePageId   : null,
    setMainPageActivePageId: () => {},
    isVisibleFeedbackForms : false,
    isResumeFormVisible    : false,
    setIsResumeFormVisible : () => {}
});

const { Provider } = appContext;

const AppProvider = ({ children, isVisibleFeedbackForms }: { children: ReactElement, isVisibleFeedbackForms: boolean }) => {
    const [isContactFormVisible, setIsContactFormVisible] = useState(false);
    const [isRespondFormVisible, setIsRespondFormVisible] = useState(false);
    const [isResumeFormVisible, setIsResumeFormVisible] = useState(false);
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [vacancyTitle, setVacancyTitle] = useState('');
    const [huntflowId, setHuntflowId] = useState('');
    const [huntflowId2, setHuntflowId2] = useState('');
    const [activePageId, setActivePageId] = useState<string | null>(null);

    return (
        <Provider value={{
            isContactFormVisible,
            setIsContactFormVisible,
            isRespondFormVisible,
            setIsRespondFormVisible,
            isNavVisible,
            setIsNavVisible,
            vacancyTitle,
            setVacancyTitle,
            huntflowId,
            setHuntflowId,
            huntflowId2,
            setHuntflowId2,
            mainPageActivePageId   : activePageId,
            setMainPageActivePageId: setActivePageId,
            isVisibleFeedbackForms,
            isResumeFormVisible,
            setIsResumeFormVisible
        }}
        >
            {children}
        </Provider>
    );
};

const useAppContext = () => useContext(appContext);

export { appContext, useAppContext, AppProvider };
